import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const A4 = ({ ...props }) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "placeholder.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 210
            height: 297
            quality: 75
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `)

  return (
    <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} {...props} />
  )
}

export default A4
