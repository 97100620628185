import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Metas from "../components/Metas"
import Pagination from "../components/Pagination"
import PublicationListItem from "../components/ListItems/PublicationListItem"
import PageHeader from "../components/PageHeader"

const PublicationList = ({ pageContext, data, location }) => {
  const { currentPage, numPages } = pageContext

  return (
    <Layout>
      <Metas title="Le kiosque" location={location}/>
      <PageHeader title="Le kiosque" />
      <section className="section page-content">
        <div className="container mx-auto px-4">
          <div className="columns grid grid-cols-1 xl:grid-cols-2 gap-8">
            {data.publications.nodes.map((node, index) => (
              <div key={index} className="column publication">
                <PublicationListItem content={node} />
              </div>
            ))}
          </div>

          <Pagination
            path={location.pathname}
            currentPage={currentPage}
            numPages={numPages}
          />
        </div>
      </section>
    </Layout>
  )
}

export default PublicationList

export const query = graphql`
  query publicationListQuery($skip: Int!, $limit: Int!) {
    publications: allNodePublication(
      sort: { fields: created, order: DESC }
      filter: { status: { eq: true } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        date_text: field_date_text
        relationships {
          image: field_image {
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        width: 247
                        height: 350
                        quality: 75
                        transformOptions: { cropFocus: CENTER }
                        layout: CONSTRAINED
                    )
                }
            }
          }
          file: field_fichier {
            localFile {
              publicURL
              prettySize
            }
          }
        }
      }
    }
  }
`
