import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import A4 from "../Images/A4"

const PublicationListItem = ({ content }) => {
  const imageHeight = (29.7 / 21) * 100 // A4 = 21x29.7

  return (
    <article className="publication-list-item">
      <div className="flex flex-wrap items-center">
        <div className="relative w-2/5 sm:w-1/5">
          <div
            aria-hidden={true}
            className="image-placeholder"
            style={{ paddingBottom: `${imageHeight}%` }}
          ></div>
          <figure className="image absolute inset-0 overflow-hidden shadow">
            {content.relationships.image ? (
              <GatsbyImage
                image={
                  content.relationships.image.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt={content.title}
              />
            ) : (
              <A4 alt={content.title} className="absolute inset-0" />
            )}
          </figure>
        </div>
        <div className="w-3/5 sm:w-4/5 pl-4 sm:px-8 py-8">
          <h3 className="title h3 mb-4">
            <span>{content.title}</span>
          </h3>
          {content.date_text && (
            <div className="uppercase text-lg font-semibold mb-4">
              {content.date_text}
            </div>
          )}
          <div className="buttons flex flex-row flex-wrap lg:items-center space-x-4">
            <a
              href={content.relationships.file.localFile.publicURL}
              title={`${content.title} (${content.relationships.file.localFile.prettySize})`}
              target="_blank"
              rel="noreferrer"
              className="button button-primary group px-5 sm:px-8"
            >
              <span
                className="icon sm:mr-2 group-hover:text-primary-light"
                aria-hidden="true"
              >
                <i className="fas fa-eye"></i>
              </span>
              <span className="sr-only sm:not-sr-only">Visualiser</span>
            </a>
            <a
              href={content.relationships.file.localFile.publicURL}
              title={`${content.title} (${content.relationships.file.localFile.prettySize})`}
              target="_blank"
              rel="noreferrer"
              className="button button-primary px-5 sm:px-8 inline-flex group"
              download
            >
              <span
                className="icon sm:mr-2 group-hover:text-primary-light"
                aria-hidden="true"
              >
                <i className="fas fa-download"></i>
              </span>
              <span className="sr-only sm:not-sr-only">Télécharger</span>
            </a>
          </div>
        </div>
      </div>
    </article>
  )
}

PublicationListItem.propTypes = {
  content: PropTypes.object.isRequired,
}

export default PublicationListItem
